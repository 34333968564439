<template>
    <div class="empty_box">
        <a-icon type="solution" style="font-size:36px"/>
        <div>{{text}}</div>
    </div>
</template>
<script>
export default {
    props:{
        text:{
            type:String,
            default:'暂无数据'
        }
    }
}
</script>
<style lang="scss" scoped>
.empty_box{
    width: 150px;
    margin: 26px auto;
}
</style>