var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',[_c('a-row',[_c('a-col',{staticStyle:{"border":"1px solid #ccc","border-radius":"2px","padding-bottom":"12px"},attrs:{"span":11}},[_c('div',{staticClass:"title_tips"},[_c('span',[_vm._v(_vm._s(_vm.tableList.length)+"项")]),_c('span',[_vm._v("群聊列表")])]),_c('div',{staticClass:"nav_search"},[_c('a-input-search',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入群名称搜索","enter-button":""},on:{"search":_vm.onSearch},model:{value:(_vm.nick_name),callback:function ($$v) {_vm.nick_name=$$v},expression:"nick_name"}}),_c('a-select',{staticStyle:{"width":"200px"},attrs:{"show-search":"","label-in-value":"","value":_vm.tagName,"placeholder":"请选择群标签","filter-option":false,"not-found-content":_vm.fetching ? undefined : null},on:{"search":_vm.searchTagList,"change":_vm.handleTagChange}},[(_vm.fetching)?_c('a-spin',{attrs:{"slot":"notFoundContent","size":"small"},slot:"notFoundContent"}):_vm._e(),_vm._l((_vm.tagList),function(d){return _c('a-select-option',{key:d.id},[_vm._v(" "+_vm._s(d.name)+" ")])})],2)],1),_c('a-table',{attrs:{"row-selection":{
          selectedRowKeys: _vm.selectedRowKeys,
          onChange: _vm.onSelectChange,
          getCheckboxProps: function (record) { return ({
            props: {
              disabled: _vm.selectedRowId.includes(record.chatroom_id), // Column configuration not to be checked
            },
          }); },
        },"columns":_vm.columns,"data-source":_vm.tableList,"scroll":{ y: 340 },"rowKey":function (record, index) { return index; },"loading":_vm.tbl_loading,"pagination":false}}),_c('base-pagination',{attrs:{"currentPage":_vm.pagination.current,"pageSize":_vm.pagination.page_size,"total":_vm.pagination.total},on:{"onChange":_vm.onChangePagination,"onShowSizeChange":_vm.onChangePagination}})],1),_c('a-col',{attrs:{"span":2}},[_c('div',{staticClass:"transfer_box"},[_c('a-button',{attrs:{"type":"link","disabled":!_vm.disableBtnRight},on:{"click":_vm.recordGroup}},[_c('a-icon',{staticStyle:{"font-size":"30px"},attrs:{"type":"right-square"}})],1),_c('a-button',{attrs:{"type":"link","disabled":!_vm.disableBtnLeft},on:{"click":_vm.deleteRecordGroup}},[_c('a-icon',{staticStyle:{"font-size":"30px"},attrs:{"type":"left-square"}})],1)],1)]),_c('a-col',{staticStyle:{"border":"1px solid #ccc","border-radius":"2px","padding-bottom":"12px"},attrs:{"span":11}},[_c('div',{staticClass:"title_tips"},[_c('span',[_vm._v(_vm._s(this.recordTableList.length)+"项")]),_c('span',[_vm._v("已选择的群聊")])]),_c('a-table',{attrs:{"row-selection":{
          selectedRowKeys: _vm.recordRowKeys,
          onChange: _vm.onSelectSureChange,
        },"columns":_vm.rightColumns,"data-source":_vm.recordTableList,"scroll":{ y: 340 },"rowKey":function (record, index) { return index; },"pagination":false,"locale":_vm.locale}},[_c('div',{attrs:{"slot":"action"},slot:"action"},[_c('a',{staticStyle:{"color":"red"},attrs:{"type":"link"}},[_vm._v("删除")])])]),_c('base-pagination',{attrs:{"currentPage":_vm.paginationRecord.current,"pageSize":_vm.paginationRecord.page_size,"total":_vm.paginationRecord.total},on:{"onChange":_vm.onRecordPagination,"onShowSizeChange":_vm.onRecordPagination}})],1)],1),_c('div',{staticClass:"footer_btn"},[_c('a-button',{attrs:{"loading":_vm.returnLoading},on:{"click":function($event){return _vm.saveReturn(1)}}},[_vm._v("保存返回")]),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":function($event){return _vm.saveReturn(2)}}},[_vm._v("发送广播")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }