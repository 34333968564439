<template>
  <a-card>
    <a-row>
      <a-col
        :span="11"
        style="border: 1px solid #ccc; border-radius: 2px; padding-bottom: 12px"
      >
        <div class="title_tips"><span>{{tableList.length}}项</span><span>群聊列表</span></div>
        <div class="nav_search">
          <a-input-search
            placeholder="请输入群名称搜索"
            enter-button
            @search="onSearch"
            style="width: 200px"
            v-model="nick_name"
          />
          <a-select
            show-search
            label-in-value
            :value="tagName"
            placeholder="请选择群标签"
            style="width: 200px"
            :filter-option="false"
            :not-found-content="fetching ? undefined : null"
            @search="searchTagList"
            @change="handleTagChange"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in tagList" :key="d.id">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </div>
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            getCheckboxProps: (record) => ({
              props: {
                disabled: selectedRowId.includes(record.chatroom_id), // Column configuration not to be checked
              },
            }),
          }"
          :columns="columns"
          :data-source="tableList"
          :scroll="{ y: 340 }"
          :rowKey="(record, index) => index"
          :loading="tbl_loading"
          :pagination="false"
        >
        </a-table>
        <!-- 分页配置 -->
        <base-pagination
          :currentPage="pagination.current"
          :pageSize="pagination.page_size"
          :total="pagination.total"
          @onChange="onChangePagination"
          @onShowSizeChange="onChangePagination"
        >
        </base-pagination>
      </a-col>
      <a-col :span="2">
        <div class="transfer_box">
          <a-button
            type="link"
            :disabled="!disableBtnRight"
            @click="recordGroup"
            ><a-icon type="right-square" style="font-size: 30px"
          /></a-button>
          <a-button
            type="link"
            :disabled="!disableBtnLeft"
            @click="deleteRecordGroup"
            ><a-icon type="left-square" style="font-size: 30px"
          /></a-button>
        </div>
      </a-col>
      <a-col
        :span="11"
        style="border: 1px solid #ccc; border-radius: 2px; padding-bottom: 12px"
      >
        <div class="title_tips">
          <span>{{ this.recordTableList.length }}项</span
          ><span>已选择的群聊</span>
        </div>
        <!-- <div class="nav_search">
          <a-input-search
            placeholder="请输入群名称搜索"
            enter-button
            @search="onSearch"
            style="width: 220px"
            v-model="rightCatroom"
          />
        </div> -->
        <a-table
          :row-selection="{
            selectedRowKeys: recordRowKeys,
            onChange: onSelectSureChange,
          }"
          :columns="rightColumns"
          :data-source="recordTableList"
          :scroll="{ y: 340 }"
          :rowKey="(record, index) => index"
          :pagination="false"
          :locale="locale"
        >
          <div slot="action">
            <a type="link" style="color: red">删除</a>
          </div>
        </a-table>
        <!-- 分页配置 -->
        <base-pagination
          :currentPage="paginationRecord.current"
          :pageSize="paginationRecord.page_size"
          :total="paginationRecord.total"
          @onChange="onRecordPagination"
          @onShowSizeChange="onRecordPagination"
        >
        </base-pagination>
      </a-col>
    </a-row>
    <div class="footer_btn">
      <a-button @click="saveReturn(1)" :loading="returnLoading"
        >保存返回</a-button
      >
      <a-button
        type="primary"
        style="margin-left: 8px"
        @click="saveReturn(2)"
        :loading="saveLoading"
        >发送广播</a-button
      >
    </div>
  </a-card>
</template>
<script>
import vEmpty from "@/components/v-empty.vue";
import debounce from "lodash/debounce";
const columns = [
  {
    title: "群名称",
    dataIndex: "nick_name",
  },
  {
    title: "群主",
    dataIndex: "chatroom_owner_nick_name",
    width: 160,
  },
  {
    title: "群成员数量",
    dataIndex: "member_count",
  },
];
const rightColumns = [
  {
    title: "群名称",
    dataIndex: "nick_name",
  },
];
export default {
  data() {
    this.searchTagList = debounce(this.searchTagList, 800);
    return {
      columns,
      rightColumns,
      tableList: [],
      nick_name: "",
      bat_loading: false,
      tbl_loading: false,
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      selectedRowKeys: [],
      selectedRowArr: [],
      selectedRowId: [],
      recordRowKeys: [],
      recordRowKeysArr: [],
      recordRowKeysID: [],
      paginationRecord: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      recordTableList: [],
      locale: {
        emptyText: <vEmpty text="你还没添加任何群聊" />,
      },
      // rightCatroom: "",
      fetching: false,
      tagName: [],
      tagList: [],
      windowId: this.$route.query.id,
      returnLoading: false,
      saveLoading: false,
    };
  },
  mounted() {
    this.selectTagList(1, 50);
    console.log("this.windowId", this.windowId);
    if (this.windowId) {
      this.getInitData();
    } else {
      this.getGroupList(1, 10);
    }
  },
  components: {
    vEmpty,
  },
  computed: {
    disableBtnLeft() {
      return this.recordRowKeys.length > 0;
    },
    disableBtnRight() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {
    // 编辑页初始
    async getInitData() {
      await this.getRecordTableList(1, 20);
      this.getGroupList(1, 10);
    },
    // 群聊列表
    getGroupList(current, page_size) {
      let id = this.recordTableList.map(({ chatroom_id }) => chatroom_id);
      let record_id = [...id, ...this.selectedRowId];
      record_id = record_id.toString();
      let params = {
        nick_name: this.nick_name,
        page_num: current,
        page_size,
        exclude_chatroom_ids: record_id || null,
      };
      this.tbl_loading = true;
      this.$api.core.GroupChat.addGroupChat(params)
        .then((res) => {
          if (res.code == 200) {
            this.tableList = res.data.list;
            this.pagination = res.data.pagination;
          }
        })
        .finally(() => {
          this.tbl_loading = false;
        });
    },
    // 选定的群聊穿梭右侧列表
    async getRecordTableList(current, page_size) {
      let params = {
        window_id: this.windowId,
        page_num: current,
        page_size,
        // chatroom_name: this.rightCatroom,
      };
      await this.$api.core.GroupChat.editRecordGroup(params).then((res) => {
        if (res.code == 200) {
          this.recordTableList = res.data.list;
          this.paginationRecord = res.data.pagination;
        }
      });
    },

    // 标签列表
    selectTagList(current, page_size) {
      let params = {
        name: this.tagName,
        page_num: current,
        page_size,
      };
      this.fetching = true;
      this.$api.core.GroupChat.getTagList(params)
        .then((res) => {
          if (res.code == 200) {
            this.tagList = res.data.list;
            // this.pagination = res.data.pagination;
          }
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    // 模糊搜索标签
    searchTagList(value) {
      this.tagName = value;
      this.selectTagList(1, 50);
    },
    // 选择某个标签群
    handleTagChange(value) {
      this.tagName = value;
      let record_id = this.recordTableList.map(
        ({ chatroom_id }) => chatroom_id
      );
      record_id = record_id.toString();
      let params = {
        id: value.key,
        exclude_chatroom_ids: record_id,
      };
      this.tbl_loading = true;
      this.$api.core.GroupChat.tagAboutChatList(params)
        .then((res) => {
          if (res.code == 200) {
            this.tableList = res.data.chatrooms;
            this.pagination = {
              total: res.data.chatrooms.length,
              current: 1,
              page_size: 10,
            };
          }
        })
        .finally(() => {
          this.tbl_loading = false;
        });
    },
    // 向右侧穿梭
    recordGroup() {
      this.getGroupList(1, 10);
      this.recordTableList = this.recordTableList.concat(this.selectedRowArr);
      this.paginationRecord.total = this.recordTableList.length;
      this.selectedRowKeys = [];
      this.selectedRowId = [];
    },
    // 向左侧穿梭
    deleteRecordGroup() {
      const screen_arr = this.recordTableList.filter((item) => {
        return !this.recordRowKeysID.includes(item.chatroom_id);
      });
      this.recordTableList = screen_arr;
      this.recordRowKeys = [];
      this.recordRowKeysID = [];
      this.getGroupList(1, 10);
    },
    onSearch() {
      this.getGroupList(1, 10);
    },
    onChangePagination(current, page_size) {
      this.getGroupList(current, page_size);
    },

    // 左侧表格选择项
    onSelectChange(selectedRowKeys, record) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowArr = JSON.parse(JSON.stringify(record));
      this.selectedRowId = record.map(({ chatroom_id }) => chatroom_id);
    },
    // 右侧表格选择项
    onSelectSureChange(selectedRowKeys, record) {
      this.recordRowKeys = selectedRowKeys;
      this.recordRowKeysArr = JSON.parse(JSON.stringify(record));
      this.recordRowKeysID = record.map(({ chatroom_id }) => chatroom_id);
    },
    // 右侧表格分页变更
    onRecordPagination(current, page_size) {
      this.paginationRecord.current = current;
    },
    // 创建/编辑广播保存返回
    saveReturn(type) {
      let chatroomIds = this.recordTableList.map(
        ({ chatroom_id }) => chatroom_id
      );
      if (this.windowId) {
        this.editSave(chatroomIds, type);
      } else {
        this.createSave(chatroomIds, type);
      }
    },
    createSave(value, type) {
      let payLoad = {
        chatroom_ids: value,
      };
      if (type == 1) {
        this.returnLoading = true;
      } else {
        this.saveLoading = true;
      }
      this.$api.core.GroupChat.createGroupMessage(payLoad)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("保存成功");
            setTimeout(() => {
              if (type == 1) {
                this.$router.push({
                  path: "/wechatMessage/group",
                });
              } else {
                this.$router.push({
                  path: "/wechatMessage/send",
                  query: {
                    id: res.data.id,
                  },
                });
              }
            }, 500);
          }
        })
        .finally(() => {
          this.returnLoading = false;
          this.saveLoading = false;
        });
    },
    editSave(value, type) {
      let payLoad = {
        chatroom_ids: value,
        window_id: this.windowId,
      };
      if (type == 1) {
        this.returnLoading = true;
      } else {
        this.saveLoading = true;
      }
      this.$api.core.GroupChat.saveEditGroup(payLoad)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("保存成功");
            setTimeout(() => {
              if (type == 1) {
                this.$router.push({
                  path: "/wechatMessage/group",
                });
              } else {
                this.$router.push({
                  path: "/wechatMessage/send",
                  query: {
                    id: res.data.id,
                  },
                });
              }
            }, 500);
          }
        })
        .finally(() => {
          this.returnLoading = false;
          this.saveLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.title_tips {
  padding: 8px 18px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
}
.nav_search {
  display: flex;
  justify-content: space-between;
  padding: 12px 18px;
}
.transfer_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  font-size: 30px;
}
.footer_btn {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}
</style>